import Widget from "../../../../components/core/Widget";
import {Form} from "react-final-form";
import TextField from "../../../../components/FinalForm/TextField/TextField";
import Button from "../../../../components/Button/Button";
import {FiSearch, FiTrash} from "react-icons/fi";
import React from "react";
import ChannelField from "../../../../components/FinalForm/ApiFields/ChannelField";
import SwitchField from "../../../../components/FinalForm/Switch/SwitchField";
import SelectField from "../../../../components/FinalForm/SelectField/SelectField";
import {useTranslation} from "react-i18next";

const Filters = ({onSearch, categories}) => {

    const {t} = useTranslation()

    return (
        <Widget title={t('basics.filters')}>
            <Form
                onSubmit={onSearch} render={({handleSubmit, form}) => (
                <>
                    <div className='grid grid-cols-1 lg:grid-cols-4 lg:gap-4'>
                        <div className='w-full'>
                            <TextField label={t('tickets.enigooId')} name={'id'} inputType={'text'}/>
                        </div>
                        <div className='w-full'>
                            <TextField label={t('tickets.sector')} name={'sector'} inputType={'text'}/>
                        </div>
                        <div className='w-full'>
                            <TextField label={t('tickets.row')} name={'row'} inputType={'text'}/>
                        </div>
                        <div className='w-full'>
                            <TextField label={t('tickets.place')} name={'place'} inputType={'text'}/>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
                        <div className='w-full'>
                            <ChannelField label={t('distributionChannels.single')} name={'channels'} isMulti={false} placeholder={t('placeholders.channel')} isClearable={true}/>
                        </div>
                        <div className='w-full'>
                            <SelectField label={t('tickets.category')} name={'categoryName'} isMulti={false}
                                         options={categories.map(item => {
                                             return {id: item, name: item}
                                         })}
                                         placeholder={t('placeholders.category')} isClearable={true}/>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
                        <div className='w-full'>
                            <SwitchField name={'notSold'} label={t('tickets.onlyNotPickup')}  />
                        </div>
                    </div>
                    <div className='text-right'>
                        <Button onClick={() => {
                            form.reset()
                            handleSubmit()
                        }} text={t('basics.resetForm')} rounded={true} outline={true} icon={<FiTrash/>}/>
                        <Button onClick={handleSubmit} text={t('basics.search')} rounded={true} icon={<FiSearch/>}/>
                    </div>
                </>
            )}/>
        </Widget>
    )
}

export default Filters
