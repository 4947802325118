import React from 'react';
import ReactDOM from 'react-dom';
import Admin from './pages/Admin/Admin';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import {ClearCacheProvider} from 'react-clear-cache';
import App from "./pages/Channel/App";
import FinalPage from "./pages/Channel/Final/FinalPage";
import UserVerify from "./pages/Admin/Login/Verify/UserVerify";
import {Toaster} from "react-hot-toast";
import PasswordResetCreate from "./pages/Admin/Login/Reset/PasswordResetCreate";
import PasswordReset from "./pages/Admin/Login/Reset/PasswordReset";
import "./i18n"

ReactDOM.render(
    <React.StrictMode>
        <ClearCacheProvider auto={true} duration={60000} basePath={'/assets'}>
            <Router>
                <Routes>
                    <Route path={'/admin/*'} element={<Admin/>}/>
                    <Route path={'/user/verify/:verifyString'} element={<UserVerify/>}/>
                    <Route path={'/user/reset-password'} element={<PasswordResetCreate/>}/>
                    <Route path={'/user/reset'} element={<PasswordReset/>}/>
                    <Route path={'/channel/:channelId'} element={<App/>}/>
                    <Route path={'/channel/:channelId/order/:orderId'} element={<FinalPage/>}/>
                    <Route exact path={'/:channelId'} element={<App/>}/>
                </Routes>
            </Router>
            <Toaster
                position="top-center"
                reverseOrder={false}
                containerClassName={'text-sm'}
            />
        </ClearCacheProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
